import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper container container--large text-break" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TestSystemContainer = _resolveComponent("TestSystemContainer")!
  const _component_LegalHeader = _resolveComponent("LegalHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TestSystemContainer),
    _createVNode(_component_LegalHeader, {
      heading: 'Impressum',
      stand: true
    })
  ]))
}