
import { defineComponent } from "@vue/runtime-core"
import LegalHeader from "@/components/legal/LegalHeader.vue"
import TestSystemContainer from "@/components/TestSystemContainer.vue"

export default defineComponent({
  name: "Imprint",
  components: {
    LegalHeader,
    TestSystemContainer,
  },
})
